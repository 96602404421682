.footer {
  background-color: #005a96;
  bottom: 0;
  color: #f5f5f5;
  position: relative;
}

.footer .container {
  max-height: 323px;
  min-height: 0px;
}

.footer h1 {
  border-left: 0;
  padding-left: none;
}