.card-img-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
}

.view-more-link {
    color: #005997;
}

.title {
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.homepage.card {
    height: 157px;
    overflow: hidden;
}