.card-body {
    height: 20rem;
}

.card-img-top {
    height: 10rem;
}

.card-body {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}

.card-item {
    flex: 1 100%;
}

.card-btn, .card-btn:hover {
    color: #fff;
    border-color: #005997;
    background-color: #005997;
    box-shadow: none;
}

.card-btn:hover {
    box-shadow: 0 5px 15px rgb(0 0 0 / 5%), 0 4px 10px rgb(0 123 255 / 25%);
}